import React, { FC } from 'react'

import { styled } from '@linaria/react'
import { FormattedMessage } from 'react-intl'

import rotatePhoneSvg from 'components/designSystem/svg/rotate-phone.svg'
import { Typography } from 'components/designSystem/Typography/Typography'

export const RotatePhoneOverlayInner: FC = () => {
  return (
    <RotatePhoneOverlayInnerContainer>
      <img src={rotatePhoneSvg} alt="rotate phone" loading="lazy" />
      <Block>
        <FormattedMessage
          id="app.rotate_phone_overlay_text"
          defaultMessage="<p>По-моему, так не очень…</p><p>Будет красивее, если перевернуть экран.</p>"
          values={{
            p: (chunks) => <Typography fontSize={16}>{chunks}</Typography>,
          }}
        />
      </Block>
    </RotatePhoneOverlayInnerContainer>
  )
}

const RotatePhoneOverlayInnerContainer = styled.div`
  text-align: center;
`
const Block = styled.div`
  margin-top: var(--spacing-32px);
`
