import React, { FC, useEffect, useMemo } from 'react'

import { styled } from '@linaria/react'

import { zIndex } from 'components/designSystem/zIndex'
import {
  addStyleOverflowToBody,
  removeStyleOverflowFromBody,
} from 'functions/client'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { useWindowResize } from 'hooks/useWindowResize'

import { RotatePhoneOverlayInner } from './RotatePhoneOverlayInner'

export const RotatePhoneOverlay: FC = () => {
  const { javaScriptEnabled, touch } = useShallowEqualSelector(
    ({ systemReducer: { javaScriptEnabled, touch } }) => ({
      javaScriptEnabled,
      touch,
    })
  )

  const [width, height] = useWindowResize()

  const show = useMemo(() => {
    return (
      javaScriptEnabled && // На лендинге, где отключен JS показывать не нужно
      touch && // Только на устройствах с сенсорными экранами
      window.screen.height < 600 && // При такой высоте, контент отображается приемлемо
      window.screen.width / window.screen.height >= 12 / 9
    )
    // width, height нужны, чтобы стриггерить расчет
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [javaScriptEnabled, touch, width, height])

  // Чтобы не работал скролл под заглушкой
  useEffect(() => {
    if (show) {
      addStyleOverflowToBody()
      document.addEventListener('touchmove', preventDefault, { passive: false })
    } else {
      removeStyleOverflowFromBody()
      document.removeEventListener('touchmove', preventDefault)
    }
  }, [show])

  if (show) {
    return (
      <RotatePhoneOverlayWrapper>
        <RotatePhoneOverlayInner />
      </RotatePhoneOverlayWrapper>
    )
  }

  return null
}

const preventDefault = (event) => {
  event.preventDefault()
}

const RotatePhoneOverlayWrapper = styled.div`
  width: 100vw;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  z-index: ${zIndex.topMost};
  background: var(--background-page);
`
